<template>
  <div>
    <app-add-edit-attachment
      model-name="Factory"
      label="factory"
      :model-id="Number($route.params.id)"
      :model-attachments="modelAttachments"
    />
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      modelAttachments: []
    }
  },

  mounted() {
    this.getAttachments()
  },

  methods: {
    getAttachments() {
      let params = {
        label: 'factory',
        model_id: this.$route.params.id,
        model_name: 'factory'
      }

      axios.post('attachment/get-attachments/', params).then(res => {
        this.modelAttachments = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>